// TechnologySection.js
import React from "react";
import { Box, Container, Typography } from "@mui/material";
import techImage from "../../assets/tech.png";

const TechnologySection = () => {
  return (
    <Container sx={{ marginTop: "15px" }}>
        <Box
        sx={{
          textAlign: "center",
          color: "#603890",
          mb: 4,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            mb: 2,
            px: { xs: 2, sm: 4 },
          }}
        >
          Precision Hiring for the Education Sector
        </Typography>
        <Typography
          variant="h5"
          sx={{
            px: { xs: 2, sm: 4 },
            maxWidth: "800px",
            margin: "0 auto",
          }}
        >
          Tailored talent solutions to accelerate your organizational growth in education and technology.
        </Typography>
      </Box>

      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", color: "#603890" }}
        >
          Our Technology
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          paddingY: 5,
          backgroundColor: "#eff8ff",
        }}
      >
        {/* Left Section: Text Content */}
        <Box
          sx={{
            flex: 1,
            marginRight: 2,
            textAlign: "left",
            paddingLeft: "15px",
            borderRadius: "10px",
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#603890" }}
          >
            Precision Hiring Powered by AI
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "16px", lineHeight: 1.8, color: "#333" }}
          >
            Utilizing advanced AI tools to headhunt and assess candidates,
            ensuring the perfect fit for your business needs
          </Typography>
        </Box>

        {/* Right Section: Image */}
        <Box
          sx={{
            flex: 1,
            backgroundImage: `url(${techImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "300px",
            borderRadius: "8px",
          }}
        />
      </Box>
    </Container>
  );
};

export default TechnologySection;
