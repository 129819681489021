import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import partner from "../../assets/partner.jpg";

const PremierRecruitmentSection = () => {
  return (
    <Box sx={{ backgroundColor: "#603890", py: { xs: 6, sm: 8, md: 10 } }}>
      <Grid
        container
        spacing={4} // Horizontal spacing
        rowSpacing={6} // Vertical spacing
        sx={{
          alignItems: "center",
          justifyContent: "center",
          px: { xs: 2, sm: 4, md: 8 }, // Padding responsiveness
        }}
      >
        {/* Left Section: Image */}
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              backgroundImage: `url(${partner})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "8px",
              width: "100%", // Responsive width
              aspectRatio: "4 / 3", // Maintain aspect ratio
              height: "auto",
            }}
          />
        </Grid>

        {/* Right Section: Content */}
        <Grid item xs={12} md={7}>
          <Box>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: "bold",
                color: "#fff",
                textAlign: { xs: "center", md: "left" },
                fontSize: { xs: "1.5rem", md: "2rem" }, // Responsive font size
              }}
            >
              Your Premier Education Recruitment Partner
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "14px", md: "16px" }, // Responsive font size
                mb: 3,
                color: "#fff",
                textAlign: { xs: "center", md: "justify" },
              }}
            >
              Eduu Talents is your gateway to reliable and efficient hiring in the education sector.
            </Typography>

            {/* Bullet Points */}
            <List>
              {[
                "India’s First Exclusive Recruitment Company for Educational Institutions & EdTech Companies",
                "Trusted by 100+ Education & EdTech Organizations",
                "160+ Expert Recruiters",
                "40+ Education Talent Acquisition Managers",
                "Specialized in Rapid Headhunting",
                "20 Lacs+ Education & EdTech Professionals Network",
                "Effortless Scaling & Growth",
                "Accelerated Hiring Process",
              ].map((item, index) => (
                <ListItem
                  key={index}
                  sx={{ padding: 0, justifyContent: "center" }}
                >
                  <ListItemIcon>
                    <DoneIcon sx={{ color: "#fff" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={item}
                    primaryTypographyProps={{
                      fontSize: { xs: "14px", md: "16px" },
                      color: "#fff",
                    }}
                  />
                </ListItem>
              ))}
            </List>

            {/* Closing Text */}
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "14px", md: "16px" }, // Responsive font size
                mt: 3,
                lineHeight: 1.8,
                color: "#fff",
                display: "-webkit-box",
                WebkitLineClamp: 3, // Limit to 3 lines
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: { xs: "center", md: "justify" },
              }}
            >
              Empower your institution or organization with a recruitment partner committed to excellence. Eduu Talents delivers the right talent at the right time, helping you build tomorrow’s learning and innovation teams today.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PremierRecruitmentSection;
